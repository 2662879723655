import React from 'react'
import { Row } from 'reactstrap'
import keyvisual from '../components/2020/media/bg4.svg'
import Layout from '../components/common/Layout'
import Section from '../components/common/Section'
import { useStaticQuery, graphql } from 'gatsby'

import {Workshop} from '../components/2020/Contributions'

export default () => {

  const data = useStaticQuery(graphql`
  {
    workshops: allContentfulWorkshop(
      filter: { year: { eq: 2020 }, node_locale: { eq: "de" } }
      sort: { fields: [title], order: ASC }
    ) {
      edges {
        node {
          id
          node_locale
          title
          description {
            json
          }
          companyName
          companyLogo {
            file {
              url
            }
          }
          speaker {
            id
            name
            position
            company
            twitterHandle
            githubHandle
            linkedInProfile
            claim
            image {
              title
              localFile {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
            bio {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
        }
      }
    }
  }
  `)

const workshops = data.workshops.edges.map(({ node }) => node)

  return (
    <Layout>

      <Section xlSize={{ size: 12 }} 
        section="section section--first cta cta cta--darken cta--tertiary" bg={keyvisual}>
        <header className="page-header">
          <h1 className="outline inverted display-one">Workshops </h1>
          <h2 className=" display-two">
          Meet your workshop hosts
          </h2>
          </header>
          {workshops.map( (workshop, idx) => {
            //const dir = idx%2==0?'left':'right'
            return <Workshop workshop={workshop} dir="left" key={`workshop-${idx}`} />
          })}
        <Row />
      </Section>

    </Layout>
  )
}


